import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';

const SecondPage = ({ data }) => (
  <DefaultLayout data={data}>
    <SEO title="Page 2" siteConfig={data.site.siteMetadata} pageUrl="/page-2" />
    <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
  </DefaultLayout>
);

SecondPage.propTypes = {
  data: PropTypes.object
};

export default SecondPage;

export const query = graphql`
  query SecondPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
